import React, { createContext, useReducer, useContext } from 'react'
import { IsBrowser } from "../helpers/utils"
import StorageService from "../helpers/StorageService"
import { GlobalActionsType } from "../utils/constants"

// Define the context
const GlobalStateContext = createContext()
const GlobalDispatchContext = createContext()


const globalReducer = (state, action) => {
    switch (action.type) {
        case GlobalActionsType.TOGGLE_THEME:
            return {
                ...state,
                currentTheme: action.theme
            }
        case GlobalActionsType.CODE_THEME:
            return {
                ...state,
                codeTheme: action.theme
            }

        case GlobalActionsType.SHOW_LOGIN_MODAL:
            return {
                ...state,
                showModal: action.showModal
            }
        case GlobalActionsType.IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn
            }
        case GlobalActionsType.USER:
            return {
                ...state,
                userData: action.userData
            }
        case GlobalActionsType.PRIVACY:
            return {
                ...state,
                showPrivacy: action.showPrivacy
            }
        case GlobalActionsType.TERMS:
            return {
                ...state,
                showTerms: action.showTerms
            }
        case GlobalActionsType.DISCLAIMER:
            return {
                ...state,
                showDisclaimer: action.showDisclaimer
            }
        default:
            {
                throw new Error(`Unhandled action type: ${action.type}`)
            }
    }
}


export const GlobalProvider = ({ children }) => {
    const storage = StorageService.getService()
    const user = storage.getUser()

    const [state, dispatch] = useReducer(globalReducer, {
        currentTheme: IsBrowser ? storage.getTheme().token ? storage.getTheme().token : "dark"  : "dark",
        codeTheme: IsBrowser ? storage.getCodeTheme().theme ? storage.getCodeTheme().theme : "dark" : "dark",
        isLoggedIn: IsBrowser ?  user : false,
        userData: IsBrowser ? storage.getUser() : false,
        showPrivacy: false,
        showTerms: false,
        showDisclaimer: false
    })

    return <GlobalDispatchContext.Provider value={dispatch}>
            <GlobalStateContext.Provider value={state}>
            {children}
        </GlobalStateContext.Provider>
    </GlobalDispatchContext.Provider>
}


// Custom hooks to use dispatch and state
export const UseGlobalStateContext = () => useContext(GlobalStateContext)
export const UseGlobalDispatchContext = () => useContext(GlobalDispatchContext)

