import React, { useEffect, useState } from "react"
import Highlight, { defaultProps } from 'prism-react-renderer'
import copy from 'copy-to-clipboard'

import { normalize } from '../utils/normalize'
import { lightTheme, darkTheme } from "../themes/code"
import { CodeLink } from '../elements/shared/code'
import { UseGlobalDispatchContext, UseGlobalStateContext } from "../context/globalContext"
import { BsMoon, MdWbSunny } from "react-icons/all"
import {motion} from "framer-motion"
import { GlobalActionsType } from "../utils/constants"
import StorageService from "../helpers/StorageService"

const highlightStart = (line) => {
  return line.some((token) => token.content.includes('highlight-start'))
}

const highlightEnd = (line) => {
  return line.some((token) => token.content.includes('highlight-end'))
}

const hasIdentifier = (line) => {
  return line.some((token) => token.content.includes('$$liquidcoderID-'))
}

const hasFilename = (line) => {
  return line.some((token) => token.content.includes('$$liquidcoderFilename-'))
}

const parser = {
  link: ({ content }, key, props) => {
    const [match, alt, url] = new RegExp('!\\[(.+?)\\]\\((.+?)\\)').exec(content)
    const [before, after] = content.split(match)
    return (
      <span key={key} {...props}>
        {before}
        <CodeLink href={url}>{alt}</CodeLink>
        {after}
      </span>
    )
  },
}

const tokenType = ({ content }) => {
  switch (true) {
    case new RegExp('!\\[.+?\\]\\(.+?\\)').test(content):
      return 'link'
    default:
      return null
  }
}

const retrieveId = (line) => {

  let id = ""
  line.map((token, key) => {
    if (token.content) {
      const parts = token.content.split('-');
      if (parts.length > 1) {
        id = parts[1]
      }
    }
  })

  return id
}

const retrieveFilename = (line) => {

  let filename = ""
  line.map((token, key) => {
    if (token.content) {
      const parts = token.content.split('-');
      if (parts.length > 1) {
        filename = parts[1]
      }
    }
  })

  return filename
}

const renderTokens = (tokens, getLineProps, getTokenProps) => {
  let linesTokened = []
  let linesNumbered = []
  let lineHighlighted = false
  let id = ""
  let filename = ""

  tokens.forEach((line, i) => {
    if (highlightStart(line)) {
      lineHighlighted = true
      return false
    } else if (highlightEnd(line)) {
      lineHighlighted = false
      return false
    }

    if (hasIdentifier(line)) {
      id = retrieveId(line)
      return false
    }

    if (hasFilename(line)) {
      filename = retrieveFilename(line)
      return false
    }

    linesTokened.push(
      <div
        {...getLineProps({ line, key: i })}
        className={lineHighlighted ? 'highlight-line' : ''}
      >
        {line.map((token, key) => {
          const props = getTokenProps({ token, key })
          const type = tokenType(token)
          if (Object.keys(parser).includes(type)) {
            return parser[type](token, key, props)
          }
          return <span key={key} {...props} />
        })}
      </div>
    )

    linesNumbered.push(
      <div key={i} className={lineHighlighted ? 'highlight-line' : ''}>
        {i}
      </div>
    )
  })

  return { linesTokened, linesNumbered, id, filename }
}

const Code = ({ children, language }) => {
  const { codeTheme } = UseGlobalStateContext()
  const [copyState, setCopyState] = useState('Copy')
  const dispatch = UseGlobalDispatchContext()

  const copyCode = () => {
    copy(children)
    setCopyState('Copied!')
    setTimeout(() => {
      setCopyState('Copy')
    }, 1200)
  }

  const toggleTheme = () => {
    if (codeTheme === "dark") {
      dispatch({type: GlobalActionsType.CODE_THEME, theme: "light"})
    } else {
      dispatch({type: GlobalActionsType.CODE_THEME, theme: "dark"})
    }
  }

  const highlightProps = {
    ...defaultProps,
    ...{
      theme: codeTheme === "dark" ? darkTheme : lightTheme,
    },
  }

  useEffect (() => {
    StorageService.getService().setCodeTheme(codeTheme)
  },[codeTheme])

  const markup = normalize(children)

  return (
    <Highlight {...highlightProps} code={markup} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => {
        const { linesTokened, linesNumbered, id, filename } = renderTokens(
          tokens,
          getLineProps,
          getTokenProps
        )

        return (

          <div className={"code-block"}  id={id}>
            <div className="action-bar"
                 style={{
                   backgroundColor: codeTheme === "dark" ? "#1a1a1a" : "#efefef",
                   color: codeTheme === "dark" ? "rgb(218, 218, 218)" : "rgb(74, 74, 74)",
                   borderColor: codeTheme === "dark" ? "#292f36" : "#d0d5d7",
                 }}
            >
              {
                filename ? <div className="code-lang">{filename+"."+language.toLowerCase()}</div>
                :  <div className="code-lang">{language.toLowerCase()}</div>
              }
              <div>

              <motion.button
                whileHover={{scale: 0.9 }}
                style={{
                  backgroundColor: codeTheme === "dark" ? "#141414" : "#F1F5F9",
                  color: codeTheme === "dark" ? "rgb(218, 218, 218)" : "rgb(74, 74, 74)",
                  borderColor: codeTheme === "dark" ? "#292f36" : "#d0d5d7",
                }}
                className="copy-code"
                onClick={copyCode}
                onKeyPress={copyCode}>
                {copyState}
              </motion.button>
              <motion.button
                whileHover={{scale: 0.9 }}
                style={{
                  backgroundColor: codeTheme === "dark" ? "#141414" : "#F1F5F9",
                  color: codeTheme === "dark" ? "rgb(218, 218, 218)" : "rgb(74, 74, 74)",
                  borderColor: codeTheme === "dark" ? "#292f36" : "#d0d5d7",
                }}
                className="toggle-theme-code"
                onClick={toggleTheme}>
                {
                  codeTheme === "dark" ?
                    <motion.div  whileHover={{rotate: 180 }} >
                      <MdWbSunny  />
                    </motion.div>
                    :
                    <motion.div whileHover={{rotate: 180 }} >
                      <BsMoon  />
                    </motion.div>
                }
              </motion.button>

              </div>

            </div>
            <pre className={className} style={style}>
              <code style={{

                color: codeTheme === "dark" ? "rgb(218, 218, 218)" : "rgb(74, 74, 74)",
                borderColor: codeTheme === "dark" ? "#292f36" : "#d0d5d7",
              }} >{linesTokened}</code>

            </pre>
          </div>
        )
      }}
    </Highlight>
  )
}

export default Code