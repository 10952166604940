import React from "react"
import styled, { css } from "styled-components"
import tw from "tailwind.macro"


const VideoContainer = styled.div`
    ${tw`relative z-10`};
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    border-color: ${props => props.theme.colors.border};
    border-width: ${props => props.theme.measurements.borderWidth};
    -webkit-border-radius: ${props => props.theme.measurements.borderRadius};
    border-radius: ${props => props.theme.measurements.borderRadius};
    -moz-border-radius: ${props => props.theme.measurements.borderRadius};
    overflow: hidden;


    ${props =>

      props.isHome && css`
      border-radius: 20px 0 20px 0;
        -webkit-border-radius: 20px 0 20px 0;
        -moz-border-radius: 20px 0 20px 0;
        border-width: 0;
        overflow: hidden;

        @media (max-width: 639px) {
          border-radius: 20px 20px 0 0;
          -webkit-border-radius: 20px 20px 0 0;
          -moz-border-radius: 20px 20px 0 0;
          overflow: hidden;
      }
      `
    }
    

    iframe{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
`

const Video = ({ videoUrl, isHome, ...props }) => (


  <VideoContainer isHome={isHome}>
    
    <iframe
     src={videoUrl}
      frameBorder="0" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen/>
  </VideoContainer>
)
export default Video