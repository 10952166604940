import EncryptionService from "./EncryptionService";


let user = false
let accessToken = false
let baseServerUrl = ""
let config = {
  base_server_url: "",
  encryption_key: ""
}

const StorageService = (function(){
  let _service
  let encryption = EncryptionService.getService()


  function _getService() {
    if(!_service) {
      _service = this;
      return _service
    }
    return _service
  }

  function _isLoggedIn() {
    return _getRefreshToken()
  }

  function _setTheme(theme) {
    localStorage.setItem("theme", theme);
  }
  function _setCodeTheme(theme) {
    localStorage.setItem("code-theme", theme);
  }

  function _setUser(updated) {
      user = updated
  }

  function _getUser() {
    return user
  }

  function _getAccessToken() {
    return accessToken
  }

  function _getRefreshToken() {
    let key = _getConfig().encryption_key
    let enc = localStorage.getItem("cByrgKrISFPyDE")
    return EncryptionService.getService().decrypt(enc, key)
  }

  function _saveTokens(tokens) {
    let key = _getConfig().encryption_key
    let enc = EncryptionService.getService().encrypt(tokens.refreshToken, key)
    localStorage.setItem("cByrgKrISFPyDE", enc)
    accessToken = tokens.accessToken
  }

  function _removeTokens() {
    accessToken = false
    localStorage.removeItem("cByrgKrISFPyDE")
  }

  function _getTheme() {
    return {
      token: localStorage.getItem("theme"),
    }
  }

  function _getCodeTheme() {
    return {
      theme: localStorage.getItem("code-theme"),
    }
  }

  function _setCurrentLesson(lesson) {
    localStorage.setItem("current_lesson", lesson)
  }

  function _getCurrentLesson() {
    return localStorage.getItem("current_lesson")
  }

  function _setCurrentChapter(chapter) {
    localStorage.setItem("current_chapter", chapter)
  }

  function _getCurrentChapter() {
    return localStorage.getItem("current_chapter")
  }

    function _setConfig(value) {
        config = value
    }

    function _getConfig() {
      return config
    }

    


  return {
    getService : _getService,
    isLoggedIn: _isLoggedIn,
    setUser : _setUser,
    getUser : _getUser,
    setTheme: _setTheme,
    setCodeTheme: _setCodeTheme,
    getTheme: _getTheme,
    getCodeTheme: _getCodeTheme,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    setConfig: _setConfig,
    getConfig: _getConfig,
    saveTokens: _saveTokens,
    removeTokens: _removeTokens,
    setCurrentChapter: _setCurrentChapter,
    getCurrentChapter: _getCurrentChapter,
    setCurrentLesson: _setCurrentLesson,
    getCurrentLesson: _getCurrentLesson,

  }
})();


export default StorageService;