import React, { createContext, useReducer, useContext } from 'react'
import { IsBrowser } from "../helpers/utils"
import StorageService from "../helpers/StorageService"
import { DashboardActions } from "../utils/constants"

// Define the context
const DashboardStateContext = createContext()
const DashboardDispatchContext = createContext()


const dashboardReducer = (state, action) => {
    switch (action.type) {
        case DashboardActions.MANAGE_SUBSCRIPTION:

            return {
                ...state,
                session: action.session
            }
        default:
            {
                throw new Error(`Unhandled action type: ${action.type}`)
            }
    }
}


export const DashboardProvider = ({ children }) => {
    const storage = StorageService.getService()
    const user = storage.getUser()

    const [state, dispatch] = useReducer(dashboardReducer, {
        session: {
            loading: false,
            data: {},
            error: null
        }
    })

    return <DashboardDispatchContext.Provider value={dispatch}>
            <DashboardStateContext.Provider value={state}>
            {children}
        </DashboardStateContext.Provider>
    </DashboardDispatchContext.Provider>
}


// Custom hooks to use dispatch and state
export const UseDashboardStateContext = () => useContext(DashboardStateContext)
export const UseDashboardDispatchContext = () => useContext(DashboardDispatchContext)

