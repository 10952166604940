import aes from "crypto-js/aes"
import CryptoJS from "crypto-js"

const EncryptionService = (function(){
  let _service

  function _getService() {
    if(!_service) {
      _service = this;
      return _service
    }
    return _service
  }

  const _encrypt = function encrypt(value, key) {
    return aes.encrypt(value, key).toString()
  }

  const _decrypt = function(value, key) {
    if (!value) { return false }
    const bytes = aes.decrypt(value, key)
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  return {
    getService : _getService,
    encrypt: _encrypt,
    decrypt: _decrypt
  }
})();


export default EncryptionService;