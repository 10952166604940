// TODO: match colors with color themes

export const darkTheme = {
  backgroundColor: "#11111190",
  styles: [
    {
      types: ['comment'],
      style: {
        color: 'rgb(92, 99, 112)',
        fontStyle: 'italic',
      },
    },{
      types: ['plain'],
      style: {
        color: 'rgb(218, 218, 218)',
      },
    },
    {
      types: ['keyword', 'selector', 'changed'],
      style: {
        color: '#FF6288',
      },
    },
    {
      types: ['operator'],
      style: {
        color: 'rgb(171, 178, 191)',
      },
    },
    {
      types: ['constant', 'number'],
      style: {
        color: '#FC9868',
      },
    },
    {
      types: ['builtin', 'attr-name'],
      style: {
        color: '#15C3EF',
      },
    },
    {
      types: ['char', 'symbol'],
      style: {
        color: '#FFD866',
      },
    },
    {
      types: ['variable', 'tag', 'deleted'],
      style: {
        color: 'rgb(224, 108, 117)',
      },
    },
    {
      types: ['string', 'inserted'],
      style: {
        color: '#FFD866',
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: '#b0d5ee',
      },
    },
    {
      types: ['function'],
      style: {
        color: '#A9DC76',
      },
    },
    {
      types: ['class-name'],
      style: {
        color: 'rgb(229, 192, 123)',
      },
    },
  ],
}

export const lightTheme = {
  backgroundColor: "#F8F6F1",
  styles: [
    {
      types: ['comment'],
      style: {
        color: 'rgb(160, 161, 167)',
        fontStyle: 'italic',
      },
    },
    {
      types: ['plain'],
      style: {
        color: 'rgb(74, 74, 74)',
      },
    },
    {
      types: ['keyword', 'selector', 'changed'],
      style: {
        color: 'rgb(166, 38, 164)',
      },
    },
    {
      types: ['operator'],
      style: {
        color: 'rgb(56, 58, 66)',
      },
    },
    {
      types: ['constant', 'number', 'builtin', 'attr-name'],
      style: {
        color: 'rgb(208,3,75)',
      },
    },
    {
      types: ['char', 'symbol'],
      style: {
        color: 'rgb(1, 132, 188)',
      },
    },
    {
      types: ['variable', 'tag', 'deleted'],
      style: {
        color: 'rgb(228, 86, 73)',
      },
    },
    {
      types: ['string', 'inserted'],
      style: {
        color: 'rgb(80, 161, 79)',
      },
    },
    {
      types: ['punctuation'],
      style: {
        color: 'rgb(41,41,41)',
      },
    },
    {
      types: ['function'],
      style: {
        color: 'rgb(64, 120, 242)',
      },
    },
    {
      types: ['class-name'],
      style: {
        color: 'rgb(193, 132, 1)',
      },
    },
  ],
}