import React from "react"
import { GlobalProvider } from "./src/context/globalContext"
import { DashboardProvider } from "./src/context/dashboardContext"
import "./src/styles/tailwind.css"
import AuthenticationService from "./src/web_service/AuthenticationService"
import StorageService from "./src/helpers/StorageService"
import { MDXProvider } from "@mdx-js/react"
import Prism from "prism-react-renderer/prism"
import MDXElements from "./src/elements/mdx"
import { useSiteMetadata } from "./src/utils/useSiteMetadata.js"
import {GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
(typeof global !== "undefined" ? global : window).Prism = Prism
require("prismjs/components/prism-swift")


class TokenRefresher extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    if (StorageService.getService().isLoggedIn()) {
      const res = AuthenticationService.getService().getUser(() => {
        this.setState({ loading: false })
      })
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}

const MetadataStorer = ({ children }) => {
  const metadata = useSiteMetadata()
  StorageService.getService().setConfig(metadata)
  return <div>
    {children}
  </div>
}

export const wrapRootElement = ({ element }) => {
  return (

      <MDXProvider components={MDXElements}>
        <GlobalProvider>
          <DashboardProvider>
            <MetadataStorer>
                 <TokenRefresher>

                     {element}

                 </TokenRefresher>
            </MetadataStorer>
          </DashboardProvider>
        </GlobalProvider>
      </MDXProvider>

  )
}
