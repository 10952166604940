import React from "react"
import { InlineCode, PreformattedCode } from "../shared/code"
import {formatStringToId} from "../../helpers/utils"
import Video from "../../components/Video.js"

const H2 = ({children}) => {
  const id = formatStringToId(children)
  return (
    <h2 className={"explanation-header"} id={id}> { children }</h2>
  )
}

const P = ({children}) => {

  if (typeof children === "string") {
    if (children.includes("+id+") || children.includes("+class+")) {
      const obj = JSON.parse(children.replace(/\+/g,""))
      return <div className={obj.class} id={obj.id}> </div>
    }
  }
  return (
    <p>{children}</p>
  )
}

export default {
  pre: PreformattedCode,
  code: InlineCode,
  h2: H2,
  Video: Video,
  p: P
}