import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            siteUrl
            stripe_publishable_key
            base_server_url
            encryption_key
            web_chat_token
            web_chat_host
            recaptcha_site_key
              inMaintenance
          }
        }
      }
    `
  )
  return site.siteMetadata
}
