export const INITIAL_RESPONSE = {
  success: false,
  hasSubmitted: false,
  message: " "
}

export const UserLoggedInState = {
  LOADING: "LOADING",
  LOGGED_IN: "LOGGED_IN",
  NOT_LOGGED_IN: "NOT_LOGGED_IN"
}

export const PaymentStatus = {
  FREE: "FREE",
  PAID: "PAID",
  NOT_PAID: "NOT_PAID",
  PAYING: "PAYING"
}

export const GlobalActionsType = {
  TOGGLE_THEME: "TOGGLE_THEME",
  CODE_THEME: "CODE_THEME",
  SHOW_LOGIN_MODAL: "SHOW_LOGIN_MODAL",
  IS_LOGGED_IN: "IS_LOGGED_IN",
  PRIVACY: "PRIVACY",
  TERMS: "TERMS",
  DISCLAIMER: "DISCLAIMER",
  USER: "USER"

}

export const ModalType = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  EMAIL_CONFIRMATION: "EMAIL_CONFIRMATION"
}

export const PricingPlans = {
    FREE: "FREE",
    PREMIUM: "PREMIUM",
  SUPPORT: "SUPPORT"
}
export const BillingPeriods = {
  YEARLY: "YEARLY",
  MONTHLY: "MONTHLY",
  SUPPORT_MONTHLY: "SUPPORT_MONTHLY",
}

export const DashboardActions = {
  SUBSCRIBE: "SUBSCRIBE",
  UPDATE_PM: "UPDATE_PM",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CHANGE_PLAN: "CHANGE_PLAN",
  UPGRADE_PLAN: "UPGRADE_PLAN",
  MANAGE_SUBSCRIPTION: "MANAGE_SUBSCRIPTION",
  NONE: "NONE"
}

export const ResponseStatus = {
  Success: "Success",
  Failure: "Failure"
}

export const Menus = [
  { path: "/", text: "HOME" },
  { path: "/courses", text: "COURSES"},
  { path: "/blog", text: "BLOG"},
  { path: "/pricing", text: "PRICING"},
  { path: "/work", text: "WORK"},

]