export const  IsBrowser = typeof window !== 'undefined'

export const formatStringToId = (value) => {
    let stringValue = value.replace(/[^a-zA-Z ]/g, "")
    stringValue = stringValue.replace(/\s/g, "-");
    return stringValue + "-headerTitle"
}

export const convertTimestampToDate = (timestamp) => {
    const ts_ms = timestamp * 1000

    // initialize new Date object
    const date = new Date(ts_ms)
    const year = date.getFullYear()
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const day = ("0" + date.getDate()).slice(-2)

    return year + "-" + month + "-" + day
}