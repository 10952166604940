// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-auth-js": () => import("./../../../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-checkout-failed-js": () => import("./../../../src/pages/checkoutFailed.js" /* webpackChunkName: "component---src-pages-checkout-failed-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-coursedetail-js": () => import("./../../../src/pages/coursedetail.js" /* webpackChunkName: "component---src-pages-coursedetail-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resetpassword-js": () => import("./../../../src/pages/resetpassword.js" /* webpackChunkName: "component---src-pages-resetpassword-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms_and_conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-courses-js": () => import("./../../../src/templates/courses.js" /* webpackChunkName: "component---src-templates-courses-js" */)
}

