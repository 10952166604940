import axios from 'axios'
import StorageService from "../helpers/StorageService"


const AuthenticationService = (function(){
  let _service
  let instance
  let storage = StorageService.getService()
  let baseURL = ""
  let isAlreadyFetchingAccessToken = false;
  let subscribers = [];

  function _getService() {
    
    baseURL = storage.getConfig().base_server_url
    const accessToken = storage.getAccessToken()

    if(!_service) {
      _service = this;
      instance = axios.create({
        baseURL: baseURL,

        headers: {
          // 'Content-Type':"application/json",
          'Authorization': accessToken
          // "Access-Control-Allow-Origin": true,
          // "Access-Control-Allow-Credentials": true,
        }
      });

      instance.interceptors.response.use(
        response => { return response },
        error => {
 
          
          if (isTokenExpired(error)){
            return handleRefreshToken(error)
          }

          return Promise.reject(error)
      })

      return _service
    }

    return _service
  }


  function isTokenExpired(error) {

    if (!error.response) {
      return false
    } else if (error.response.data.code === 0) {
      return true
    }
  }

  async function handleRefreshToken(error) {
    try {

          const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(accessToken => {
              error.response.config.headers["Authorization"] = accessToken
              resolve(axios(error.response.config))
            })
        })

        
        if (!isAlreadyFetchingAccessToken) {
          const refreshToken = storage.getRefreshToken()
          
          if (!refreshToken) { 
            return Promise.reject({
              response: { data: "Login required"}
            })
          }
          const data = {
            refreshToken: refreshToken
          }

          const res = await axios.post(baseURL +"/api/refresh", JSON.stringify(data))
          if (!res.data) {
            return Promise.reject(error)
          }
          const tokens = res.data.tokens
          storage.saveTokens(tokens)
          isAlreadyFetchingAccessToken = false
          onAccessTokenFetched(tokens.accessToken)
        }

        return retryOriginalRequest
    } catch (error) {
      return Promise.reject(error)
    }


  }

  function onAccessTokenFetched(access_token) {
    subscribers.forEach(callback => callback(access_token));
    subscribers = [];
  }

  function addSubscriber(callback) {
    subscribers.push(callback);
  }

   function _signup(data, success, failure) {

    instance.post("/api/signup", JSON.stringify(data))
        .then(res => {
          success(res)
        })
        .catch(err => failure(err))
  }

  function _login(data, success, failure) {
    try {
      instance.post("/api/login", JSON.stringify(data))
      .then(res => {        
        storage.setUser(res.data.user)
        storage.saveTokens(res.data.tokens)
        success(res.data.user)
      }).catch(err => failure(err))
    } catch (error) {
      failure({ response: { data: "Unable to send request, check your internet connection"} })
    }
  }

  function _forgotPassword(data, success, failure) {
    instance.post("/api/forgotpassword", JSON.stringify(data))
      .then(res => {
        success(res)
      })
      .catch(err => failure(err))
  }

  function _resetPassword(data, success, failure) {
    instance.post("/api/resetpassword", JSON.stringify(data))
      .then(res => {
        success(res)
      })
      .catch(err => failure(err))
  }

  function _refreshToken(callback) {

    const token = storage.getRefreshToken()
    if (!token) { 
      storage.removeTokens()
      storage.setUser({})
      callback()
    }

    const data = {
      refreshToken: token
    }
    instance.post("/api/refresh", JSON.stringify(data))
      .then(res => {
        storage.saveTokens(res.data.tokens)
        callback()
      })
      .catch(err => {
        callback()
        console.log(err)
      })
  }

  function _getClientSecret(data, success, failure) {
    instance.post("/api/payment_intent",  JSON.stringify(data))
      .then(res => {
        success(res)
      })
      .catch(err => {
        failure(err)
      })
  }

  async function _getUser(callback) {
    const data = {
      token: storage.getAccessToken(),
    }

    try {
      const res =  await instance.post("/api/user", JSON.stringify(data))
      storage.setUser(res.data)
      callback()
    } catch (error) {
      storage.setUser(false)
      callback()
    }
  }

  function _resend_confirmation(data,  success, failure) {

    instance.post("/api/resend_confirmation_email", JSON.stringify(data))
      .then(res => {
        success(res)
      }).catch(err => {
        failure(err)
      })
  }

  function _subToNewsletter(data,  success, failure) {

    instance.post("/api/newsletter", JSON.stringify(data))
      .then(res => {
        success(res)
      }).catch(err => {
        failure(err)
      })
  }

  function _logout(callback) {
    const token = storage.getRefreshToken()
    storage.removeTokens()
    storage.setUser(false)
      
    const data = {
      refreshToken: token
    }
    instance.post("/api/logout", JSON.stringify(data))
      .then(res => { callback() })
      .catch(err => { callback() })
  }

   async function  _createSubscription(data) {
       return await instance.post("/api/create_subscription", JSON.stringify(data))
  }

  async function _retryInvoice(data) {
    return await instance.post("/api/retry_invoice", JSON.stringify(data))
  }

  async function _changePlan(data) {
    try {
      const res = await instance.post("/api/change_plan", JSON.stringify(data))
      return res.data
    } catch (error) {
      return false
    }
  }

  async function _updatePaymentMethod(data) {
    return await instance.post("/api/update_pm", JSON.stringify(data))
  }


  async function _cancelSubscription(data) {

    try {
      const res = await instance.post("/api/cancel_subscription", JSON.stringify(data))
      return res.data
    } catch (error) {
      return false
    }
  }


  async function _restartSubscription(data) {
    try {
      const res = await instance.post("/api/restart_subscription", JSON.stringify(data))
      return res.data
    } catch (error) {
      return false
    }
  }

  async function _fetchPortalSession(data) {
    try {
      const res = await instance.post("/api/custom_portal_session", JSON.stringify(data))
      return res.data
    } catch (error) {
      return false
    }
  }


  async function _updateUserChatId(data) {
    try {
      const res = await instance.post("/api/update_user_chat_id", JSON.stringify(data))
      return res.data
    } catch (error) {
      return false
    }
  }

  return {
    getService : _getService,
    signUp: _signup,
    login: _login,
    logout: _logout,
    forgotPassword: _forgotPassword,
    resetPassword: _resetPassword,
    refreshToken: _refreshToken,
    getClientSecret: _getClientSecret,
    getUser: _getUser,
    resend_confirmation: _resend_confirmation,
    createSubscription: _createSubscription,
    retryInvoice: _retryInvoice,
    changePlan: _changePlan,
    updatePaymentMethod: _updatePaymentMethod,
    cancelSubscription: _cancelSubscription,
    restartSubscription: _restartSubscription,
    subToNewsletter: _subToNewsletter,
    updateUserChatId: _updateUserChatId,
    fetchPortalSession: _fetchPortalSession
  }
})();


export default AuthenticationService;
